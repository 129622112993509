import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
      <nav class="menu">
        <ul>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('about')
              }}
            >
              About Us
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle('contact')
              }}
            >
              Contact
            </button>
          </li>
        </ul>
      </nav>
      <p className="copyright">&copy; WestBridge LLC, 2018 - 2020</p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
