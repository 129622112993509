import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.svg'

const wModifier = 29
const hModifier = 15
const w = 700/29
const h = 300/15

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>

    <svg width={w * 29} height={h * 15}>
      <line x1={w * 14.5} y1={h * 15} x2={w * 14.5} y2={h * 7} ></line>

      <line x1={w * 0} y1={h * 0} x2={w * 1.75} y2={h * 7} ></line>
      <line x1={w * 14.5} y1={h * 15} x2={w * 1.75} y2={h * 7} ></line>
      <line x1={w * 5.688} y1={h * 0} x2={w * 1.75} y2={h * 7} ></line>

      <line x1={w * 3} y1={h * 0} x2={w * 19} y2={h * 9.739} ></line>
      <line x1={w * 1.5} y1={h * 0} x2={w * 10} y2={h * 9.739} ></line>

      <line x1={w * 7.882} y1={h * 0} x2={w * 14.5} y2={h * 15} ></line>

      <line x1={w * 9.675} y1={h * 4.063} x2={w * 1.75} y2={h * 7} ></line>


      <line x1={w * 29} y1={h * 0} x2={w * (29 - 1.75)} y2={h * 7} ></line>
      <line x1={w * 14.5} y1={h * 15} x2={w * (29 - 1.75)} y2={h * 7} ></line>
      <line x1={w * (29 - 5.688)} y1={h * 0} x2={w * (29 - 1.75)} y2={h * 7} ></line>


      <line x1={w * (29 - 1.5)} y1={h * 0} x2={w * 19} y2={h * 9.739} ></line>
      <line x1={w * (29 - 3)} y1={h * 0} x2={w * 10} y2={h * 9.739} ></line>

      <line x1={w * (29 - 7.882)} y1={h * 0} x2={w * 14.5} y2={h * 15} ></line>

      <line x1={w * (29 - 9.675)} y1={h * 4.063} x2={w * (29 - 1.75)} y2={h * 7} ></line>
    </svg>

    <div className="content">
      <div className="inner">
        <h1>WESTBRIDGE SOLUTIONS</h1>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('freight')
            }}
          >
            FREIGHT
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('software')
            }}
          >
            SOFTWARE
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('investments')
            }}
          >
            INVESTMENTS
          </button>
        </li>
      </ul>
    </nav>
    <div className="logo">
      <span className="icon fa-diamond"></span>
    </div>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
