import PropTypes from 'prop-types'
import React from 'react'
import freightImage from '../images/freight.png'
import softwareImage from '../images/software.jpg'
import investmentsImage from '../images/investments.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="freight"
          className={`${this.props.article === 'freight' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">FREIGHT</h2>
          <span className="image main">
            <img src={freightImage} alt="" />
          </span>
          <p>
            At WestBridge, you can expect some of the best service and
            transparency in the Freight industry, including self-service solutions
            or fully managed services.
          </p>
          <p>
            We specialize in truckload freight and work with various equipment
            types like dry van, temperature controlled, oversized and flatbed.
          </p>
          <p>
            Our diverse, centralized marketplace connects you to the data
            intelligence and capacity solutions needed to take your supply
            chain further, faster.
          </p>
          {close}
        </article>

        <article
          id="software"
          className={`${this.props.article === 'software' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">SOFTWARE</h2>
          <span className="image main">
            <img src={softwareImage} alt="" />
          </span>
          <p>
          Westbridge is devoting significant resources to technological
          development on behalf of our customers around the globe.
          We believe that innovation, propelled by scale, creates better ways
          to move goods into the hands of the people who need them.
          </p>
          <p>
          Our technologies are embedded in every area of operations, focused
          on four areas of innovation: automation and intelligent machines,
          visibility and customer service, our digital freight marketplace and
          dynamic data science.
          </p>
          {close}
        </article>

        <article
          id="investments"
          className={`${this.props.article === 'investments' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">INVESTMENTS</h2>
          <span className="image main">
            <img src={investmentsImage} alt="" />
          </span>
          <p>
            Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent
            eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam
            erat volutpat. Praesent urna nisi, fringila lorem et vehicula
            lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.
            Aliquam libero et malesuada fames ac ante ipsum primis in faucibus.
            Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit
            amet.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Us</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent
            eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam
            erat volutpat. Praesent urna nisi, fringila lorem et vehicula
            lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.
            Aliquam libero et malesuada fames ac ante ipsum primis in faucibus.
            Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit
            amet.
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
